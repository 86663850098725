// https://www.gatsbyjs.org/docs/browser-apis/

// Scroll to anchor on page refresh
const scrollToAnchor = hash => {
  const id = hash.split("#").pop();
  if (!id) return;
  const section = document.getElementById(id);
  if (!section) return;
  section.scrollIntoView({
    behavior: 'smooth'
  });
};

exports.onInitialClientRender = () => {
  const hash = window.location.hash;
  if (hash) {
    scrollToAnchor(hash);
  }
  window.customElements.define('app-nobreak', class extends HTMLElement {});
  window.customElements.define('app-trademark', class extends HTMLElement {});
  window.customElements.define('app-plus', class extends HTMLElement {});
};
